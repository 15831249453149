<template>
  <div>
    <statistics-slider resource-type="resort" :resource-uuid="resourceUuid"/>
    <menu-card class="mb-base" :identifier="this.$route.params.uuid" :pannels="pannels"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import MenuCard from "@/modules/Shared/Components/menu-card/MenuCard.vue";
import resortMenuItems from "./resortMenuItems.js"
import store from '@/modules/Shared/Store/store'

import {generateMenu} from "@/modules/Shared/Helpers/menuHelper.js";
import osmTokenHelper from "@/modules/Shared/Helpers/osmTokenHelper.js";
import StatisticsSlider from "@/modules/Statistics/Components/StatisticsSlider";
import cloneDeep from "lodash.clonedeep"

export default {
  components: {
    StatisticsSlider,
    MenuCard
  },
  data: function() {
    return {
      resort: Object,
      items: Array
    }
  },
  async created(){
    this.items = cloneDeep(resortMenuItems)
    await this.updateResort()
  },
  mounted() {
    this.UPDATE_RESORT_UUID(this.$route.params.uuid);
  },
  async updated() {
    await this.updateResort()
  },
  computed: {
    pannels() {
      const features = this.resort.is_ski_resort ? ['is_ski_resort'] : []
      return generateMenu(this.items, this.$route.params.uuid, features)
    },
    resourceUuid() { return this.$route.params.uuid }
  },
  methods: {
    ...mapMutations( ['UPDATE_RESORT_UUID']),
    addUrlToOsmRoute(token){
      const listitems = this.getListitemsFromItemName('pois');
      const index = this.items.indexOf(listitems);
      if(listitems && index !== -1 && Array.isArray(listitems.items)){
        const indexofListITems = this.items[this.items.indexOf(listitems)]
        const routeOsm = indexofListITems.items.find(item => item.route.name == 'pois');
        const indexrouteOsm = indexofListITems.items.indexOf(routeOsm);
        const idEditorDomain = process.env.VUE_APP_IDEDITOR_DOMAIN;
        this.items[index].items[indexrouteOsm].route.path = `${idEditorDomain}/?token=${token}#background=Bing&disable_features=boundaries&map=16.00/${this.resort.lat}/${this.resort.lng}`;
      }
    },
    removeItemPannelByItemName(name){
      const listitems = this.getListitemsFromItemName(name);
      const index = this.items.indexOf(listitems);
      if(listitems && index !== -1 && Array.isArray(listitems.items)){
          this.items[index].items = listitems.items.filter(item => item.route.name !== name)
      }
    },
    getListitemsFromItemName(name){
      return this.items.find(listitems => listitems.items.find(item => 'route' in item && item.route.name == name));
    },
    async getResort() {
      const resortUuid = this.$route.params.uuid
      return store.getters['resorts/resorts'].find(resource => resource.uuid === resortUuid)
    },

    async updateResort() {
      this.resort = await this.getResort()
      const resortDrm = store.getters['auth/drmResource']('resorts', this.resort.uuid).drm
      //Si la estació no té coordenades, no mostrem el Editor id
      if(this.resort.lat && this.resort.lng && resortDrm.includes('osm')) {
        //Obtenim la cookie necessaria per a mostrar el Editor id
        const token = await osmTokenHelper.get(this.resort.uuid);
        //Genero les dades necessaries per obrir el Editor ID en una nova tab
        this.addUrlToOsmRoute(token);
      } else {
        this.removeItemPannelByItemName('pois');
      }
    }
  }
};
</script>
